import React, { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, Row, Col } from 'antd';
import { WhatsAppOutlined, LoadingOutlined } from '@ant-design/icons';
import Header from './leap.png'

import './ImageSearch.css';
import { Spin } from 'antd';

const customSpinner = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Spinner = () => (
  <div className="spinner-container">
    <Spin indicator={customSpinner} />
  </div>
);

const helloMessage = 'Hey wanted to share this really cool image I created! Check out leapml.dev for more info. '

const ImageDisplay = ({ imageUrls }) => (
  <div className="image-display-container">
    {imageUrls.map((url, index) => (
      <div style={{marginBottom:'15px'}} key={index}>
        <img src={url.uri} alt={`Image ${index + 1}`} />
        <Button
          href={`https://wa.me/?text=${helloMessage}${url.uri}`}
          target="_blank"
        >
          Share  <WhatsAppOutlined style={{color:'green'}} />
        </Button>
      </div>
    ))}
  </div>
);

const ImageSearch = () => {
  const [prompt, setPrompt] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const serv = 'https://openai-juan.herokuapp.com/';
  const localh = 'http://localhost:5001/'
  const handleClick = async () => {
    setFetchLoading(true);
    try{
        const res = await axios.post(`${serv}api/leaphack`, {prompt});
        res && res.data && setImageUrls(res.data.imageURLs);
        setFetchLoading(false);
    }catch(e){
        setFetchLoading(false);
        console.error(e, "FATAL ERRORs");
    }
  
  };

  const handleSubmit = (event) => {

    // Here you would fetch the images using the prompt from an API or a database.
    // For this example, we'll just use a static list of image URLs.
    const staticImageUrls = [
      'https://dreamtrain.s3.us-west-2.amazonaws.com/image-gen-b428d570-2b9b-412d-b704-30b6a91168c5/generated_images/0.png',
      'https://dreamtrain.s3.us-west-2.amazonaws.com/image-gen-e1f34250-b3bc-48b6-9e07-4a629f0e6957/generated_images/0.png',
      'https://dreamtrain.s3.us-west-2.amazonaws.com/image-gen-d5e6948e-8bd9-4686-9d44-a6e3a2c05a6a/generated_images/0.png',
      'https://dreamtrain.s3.us-west-2.amazonaws.com/image-gen-cfe7b280-c139-451a-bdcf-ea3dd2e4b526/generated_images/0.png',
      'https://dreamtrain.s3.us-west-2.amazonaws.com/image-gen-15f524f0-9fde-4c50-8fa5-bad5578de2bc/generated_images/0.png',
    ];
    setImageUrls(staticImageUrls);
  };

  return (
    <div className="image-search-container">
        <img src={Header} style={{minWidth:'300px', maxWidth:'300px'}} />
      <Form layout="inline" onFinish={handleClick}>
        <Row justify="center" align="middle">
          <Col span={16}>
            <Form.Item>
              <Input
                style={{minWidth:'300px', maxWidth:'50%'}}
                id="prompt"
                placeholder="Enter a prompt"
                value={prompt}
                onChange={(event) => setPrompt(event.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button style={{marginLeft:"10px"}}type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{dsplay:'flex', flexDirection:'column', maxWidth:'60%'}}>
       {fetchLoading && <Spinner/>}
      {imageUrls && imageUrls.length > 0 &&       

        <ImageDisplay imageUrls={imageUrls} />
}
      </div>
    </div>
  );
};

export default ImageSearch;
