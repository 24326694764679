import React, {useEffect, useState} from 'react';
import { Button} from "antd";
import axios from 'axios';
import { GoogleOutlined, MailOutlined } from '@ant-design/icons';
import { URL } from '../../config/index';

export default function SigninButtonG({fingerprint}){

    const handleAuthClick = async () => {
        const response = await axios.get(URL + "/api/auth_email", { 
          params: {
            token: fingerprint
        }});
        window.open(response.data);
        console.log(response);
      };

    return(
        <Button size="large" style={{
            color: 'white',
            border:'transparent',
            background: 'linear-gradient(to top right, #4285F4, purple)',
            
          }} onClick={handleAuthClick}>
            <GoogleOutlined /> Sign in with Google
          </Button>
    )
}