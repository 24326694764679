import {  Tree, Card, Spin, Empty } from 'antd';
import { useEffect, useState } from 'react';
import { GoogleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const LoadingCard = () => {
    return (
      <Card style={{ width: "100%",  border:'transparent' }}>
        <Spin tip="Loading Folders" size="large" style={{ color: '#1890ff' }}>
         
        </Spin>
      </Card>
    );
  };

export default function FolderTree({folderTree, basket, setBasket}) {
    const [parsedTree, setParsedTree] = useState([]);

    function file_parser( f ){
        return {
            title: f.node.title,
            key: f.node.key,
        }
    };

    function parseTree(tree) {
        return tree.map(node => {
            return {
                title: node.title,
                key: node.key,
                icon: node.icon  ? <CheckCircleOutlined style={{color:'green'}} /> : null,
                children: node.children ? parseTree(node.children) : null
            }
        })
    }

    const onSelect = (selectedKeys, info) => {
        if(basket.find( element=> element.key === info.node.key)){
            setBasket( basket => basket.filter( element=> element.key !== info.node.key))
        }
        else if(info.node.children) setBasket( basket => [...basket, file_parser(info)])
    };

    useEffect(()=>{
        const stringify = JSON.stringify(folderTree);
        const parse_string = JSON.parse(stringify);
        const parsed_tree = parseTree(parse_string);
        setParsedTree(parsed_tree);
    },[folderTree]);

    return (
        <Card style={{ width: '100%', border:'transparent', margin:'0 auto' }}>
            {parsedTree.length > 0 ? 
                <Tree
                    showLine={true}
                    showIcon={true}
                    defaultExpandedKeys={['0-0-0']}
                    onSelect={onSelect}
                    treeData={parsedTree}
                />
                : 
                <div style={{textAlign:"center", margin:'0 auto'}}>
                    <LoadingCard/>
                </div>
            }
        </Card>
    );
};
