import React, { useEffect, useState } from 'react';
import { Button } from "antd";
import axios from 'axios';
import SigninButtonG from '../SigninGoogle';
import Google from '../../MockupData/google.png';
import Openai from '../../MockupData/cw.png';
import FilesCard from '../../MockupData/cards.png';
import SyncDrivecard from '../SyncDriveCard';
import DriveTree from '../DriveTree';
import SearchBar from '../SearchBar';
import DriveSearch from '../DriveSearch';

export default function LandingPage({ fingerprint, user, driveToken, setDriveToken, displaySearch }) {
    const syncDriveCardRenders = user && !driveToken;
    const folderStructureRenders = user && driveToken;
    const landingPitch = !user;
    const [results, setResults] = useState([]);
    return (
        <>
            <div style={{ minWidth: "90%", marginTop: "50px", margin:'0 auto' }}>
                {landingPitch && <div style={{ backgroundColor: '', maxWidth: "400px", margin: '0 auto' }}>
                    <img className=""
                        style={{ width: "65%", maxWidth: '400px', margin: "0 auto", marginTop: "40px", marginRight: "-30px" }}
                        alt="example"
                        src={FilesCard}
                    />
                    <img className=""
                        style={{ width: "45%", maxWidth: '300px', margin: "0 auto", marginTop: "40px", marginLeft: "-27px" }}
                        alt="example"
                        src={Google}
                    />

                </div>}
                <br></br>
                {landingPitch && <h1 className="landing-header">Search your content.</h1>}

                <div style={{ marginTop: "20px" }}>
                    {landingPitch && <>
                                <SigninButtonG fingerprint={fingerprint} />
                                <br></br>
                                <br></br>
                                <br></br>
                             </>
                    }
                </div>
            </div>


            {landingPitch && <h5 className='landing-subh'>Find relevant documents and extracts
                <span style={{ letterSpacing: "-2px" }} className='landing-highlight'> ----- </span>  by simply asking.</h5>
            }




            <div style={{ marginTop: "50px" }}>
                {syncDriveCardRenders && <SyncDrivecard fingerprint={fingerprint} user={user} />}
            </div>

    
  
           {folderStructureRenders&& <DriveSearch 
            driveToken={driveToken} 
            setDriveToken={setDriveToken} 
            fingerprint={fingerprint} 
            user={user} 
            displaySearch={displaySearch}  
            setResults={setResults} />}
            <div style={{ display: 'flex', alignItems: 'center',margin:'0 auto', justifyContent: 'center', marginTop: "50px" }}>
                    <div style={{}}>Powered by</div>
                    <img style={{ maxWidth: "30px", marginLeft: '10px' }} src={Openai} />
                </div>

        </>
    )
}
