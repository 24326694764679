import React from "react";
import { Card, Button, Spin } from "antd";
import axios from 'axios';
import { GoogleOutlined } from '@ant-design/icons';
import {URL} from '../config/index';


const LoadingCard = () => {
    return (
      <Card style={{ width: 300, margin: 'auto',  border:'transparent' }}>
        <Spin tip="Loading Folders" size="large" style={{ color: '#1890ff' }}>
         
        </Spin>
      </Card>
    );
  };
  

const GoogleDriveCard = ({fingerprint, loadFolders}) => {
    const handleAuthClick = async () => {
        const response = await axios.get(URL + "/auth", { params: {
            token: fingerprint
        }});
        window.open(response.data);
        console.log(response);
      };
    
  return (
    <Card
      style={{ width: 300, margin: "0 auto", backgroundColor:'aliceblue'}}
      cover={
        <img
        style={{width:"20%", margin:"0 auto", marginTop:"50px"}}
          alt="example"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Google_Drive_icon_%282020%29.svg/512px-Google_Drive_icon_%282020%29.svg.png"
        />
      }
      actions={[

        fingerprint.length && loadFolders ?
         <LoadingCard/> :
        <Button type="primary" onClick={handleAuthClick}>
          <GoogleOutlined/> Connect to Google Drive
        </Button>,
      ]}
    >
      <Card.Meta
        title="Find contextual answers"
        description="Connect your Google Drive to perform semantic search over your files"
      />
              {  }

    </Card>
  );
};


export default GoogleDriveCard;