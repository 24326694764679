import React from 'react';
import { Card, Typography,  } from 'antd';
import { SearchOutlined, CopyOutlined, FilePdfOutlined } from '@ant-design/icons';

const { Meta } = Card;


export default function FileCardList({vectorObject}) {

    const ob = vectorObject.metadata;

  return (
    <div>
      <Card
      style={{ margin: '16px 0', margin:'0 auto' }}
    >
       
       
      <Meta
        style={{color:'blue'}}
        title={<Typography.Title level={4}>{ob.file_name}</Typography.Title>}
        description={ob.text}
      />
        <div style={{minWidth:'100%', margin:'0 auto'}}>
            <a href={ob.file_url}>document  <FilePdfOutlined/></a>

        </div>
    </Card>
    </div>
  );
};
