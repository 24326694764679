import React, { useState } from "react";
import { Card, Button, Spin, Divider, Tooltip, Form, Input } from "antd";
import axios from 'axios';
import { GoogleOutlined, MailOutlined } from '@ant-design/icons';
import { URL } from '../config/index';
import Google from '../MockupData/google.png';
import Openai from '../MockupData/openai.png'
import Filescard from "../MockupData/cards.png";


export default function SyncDrivecard ({ fingerprint, user }){
  const handleAuthClick = async () => {
    const response = await axios.get(URL + "/auth", { 
       params: { 
        user_id: fingerprint,

      }
    });
    window.open(response.data);
    console.log(response);
  };


  return (
    <Card
      style={{ width: 300, margin: "0 auto", backgroundColor: 'aliceblue', overflow: 'hidden' }}
      cover={
        <div>
          <img 
            style={{ width: "30%", margin: "0 auto", marginTop: "40px", marginRight: "10px" }}
            className="spin"
            alt="example"
            src={Openai}
          />
           <img className=""
                        style={{ width: "30%", maxWidth:'300px', margin: "0 auto", marginTop: "40px" }}
                        alt="example"
                        src={Google}
                    />
        </div>


      }
      actions={[
        // SignUpBar

      ]}
    >
      <Card.Meta
        title={  "Sync upon approval"}
        description={<div style={{textAlign:'left'}}>
                <p>You will be able to connect once your user has been authorized by our team.</p>
        </div>}
      />


      <Divider />

      
        <Button style={{
          color: 'white',
          background: 'linear-gradient(to top right, #4285F4, purple)',
        }} onClick={handleAuthClick}>
          <GoogleOutlined /> Connect to Google Drive
        </Button>

    </Card>
  );
};


