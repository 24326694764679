const BASE_URL = "https://whbackend.herokuapp.com/";
const headers =  { 'Content-Type': 'application/json' }

export const geoLocation = () => {
  return fetch("https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0")
    .then(response =>
      response.json()
    );
};

export async function create_db_entry(data) {
  const url = BASE_URL + "insert";
  const req_options = {
    method: 'POST', headers: headers,
    body: JSON.stringify(data)
  }
  await fetch(url, req_options)
    .then(response => {
      console.log(response, "backend repsonse")
    })
};

export const newMHDMY = () => {
  var today = new Date();
  var min = String(today.getMinutes()).padStart(2, '0');
  var hr = String(today.getHours()).padStart(2, '0');
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  return hr + ":" + min + "&" + mm + '-' + dd + '-' + yyyy;
};
