import { Row, Col } from 'antd';
import DriveTree from '../DriveTree';
import SearchBar from '../SearchBar';

export default function DriveSearchView({ fingerprint, driveToken, setDriveToken, user, displaySearch, setResults }) {
    const folderStructureRenders = user && driveToken;

    return (
        <Row>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                {folderStructureRenders && <DriveTree driveToken={driveToken} setDriveToken={setDriveToken} fingerprint={fingerprint} user={user} />}
            </Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                <Row>

                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        {/* Column 2 content */}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <div style={{ marginBottom: "20px", minWidth: "600px", margin: '0 auto', display: 'flex', flexDirection: 'row' }}>
                            {displaySearch && <SearchBar setResults={setResults} driveToken={driveToken} />}
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
