import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Divider, Spin, Tag, Button, Modal } from "antd";

import FolderTree from './Tree';
import Basket from './Basket';
import InitiateAuth from "./InitiateAuth";
import { Typography } from 'antd';
import { URL } from '../config/index';


const { Text } = Typography;

const AuthButton = ({ fingerprint, driveToken, setDriveToken, user }) => {

  const [folderData, setFolderData] = useState([]);
  const [basket, setBasket] = useState([]);
  const [loadFolders, setLoadFold] = useState(false);
  const [vectResponse, setVectRes] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [visible, setVisible] = useState("");
  const [ vectLoading, setVectLoad] = useState(false);
  const modal_visible = visible.length > 0;

  useEffect(() => {
    async function fetch_folders() {
      setLoadFold(true);
      try {
        const res = await axios.post(URL + '/api/folders', {
          driveToken: driveToken
        });
        if (res.data.e) {
          setFolderData([{ title: "No files found", key: "08-49" }]);
          setLoadFold(false);
        } else {
          setFolderData(res.data);
          setLoadFold(false);
        }
      } catch (err) {
        console.log("Folders error", err);
      }
    };
    if (driveToken && driveToken.access_token) fetch_folders();
  }, [driveToken, visible]);

  console.log(visible, "Value of visible")

  async function generate_pdf_embeddings() {
    if (!basket) return;
    try {
      setVectLoad(true);
      const res = await axios.post(URL + '/api/pdf_embeddings', {
        basket: basket,
        google_auth: driveToken
      });
      setVectRes(res.data);
      setShowSuccessMessage(true);
      setVisible("Successfully vectorized folder.");
      setVectLoad(false);

    } catch (err) {
      console.log("Folders error", err);
      setVisible("Error in vectorizing folder.");
      setVectLoad(false);

    }
  }

  return (
    <div style={{ margin: '0 auto', display: 'flex', flexDirection: 'col', 
    alignContent: 'center', justifyContent: 'center',  minWidth: "350px", maxWidth: '350px' }}>
      <Card style={{ minWidth: "100%", maxWidth: '100%' }}>


        <Card.Meta
          title={<div> <h2 className="landing-highlight">Google Drive for </h2>
            <h4 style={{ color: '#333333', marginTop: "-20x" }} >{user.user_email}</h4>

          </div>}

        />
        <div style={{ display: "flex", flexDirection: "row", height: '300px', overflow: 'auto' }}>

          <FolderTree folderTree={folderData} basket={basket} setBasket={setBasket} />
        </div>
        <Divider style={{ marginTop: "-15px", border: 'red' }} />

        <div style={{ display: "flex", flexDirection: "col", flexWrap: 'wrap', alignItems: "center", justifyContent: "center", wminWidth: '300px' }}>
          {basket.map(b => <Tag style={{ marginTop: "8px" }}>{b.title}</Tag>)}
        </div>
        <Divider />
        <Button
          size='large'
          onClick={generate_pdf_embeddings}
          style={{
            background: 'linear-gradient(to top right, #4285F4, purple)',
            color: 'white'

          }}>Vectorize folders</Button>
          {vectLoading&&<p>loading</p>}
        <Modal
          title="Success"
          visible={modal_visible}
          onOk={() => setVisible("")}
          onCancel={() => setVisible("")}
        >
          <p>{visible}</p>
        </Modal>
      </Card>
      <br></br>
    </div>

  );
};

export default AuthButton;