
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import './App.css';
import axios from 'axios';
import {geoLocation, create_db_entry, newMHDMY} from "./utils/index";
import { Space, Card, Tag, Button, Spin, Input, Divider, Layout, Dropdown } from 'antd';
import Fingerprint2 from 'fingerprintjs2';
import LandingPage from './components/LandingPage';
import { URL } from './config/index';
import GovtSearch from './components/GovSearch';
import LeapH from './LeapHack/index';
const { Search } = Input;

const items = [
  {
    key: '1',
    label: 'What was their revenue in 2021?',
  },
  {
    key: '12',
    label: 'Name a few competitors',
  },
  {
    key: '2',
    label: 'How would inflation affect Nubank?',
  },
  {
    key: '3',
    label: "How could Brazil's credit rating impact Nubank?",
  },
];
function App() {

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [backlog, setBacklog] = useState([]);
  const [geo, setGeo] = useState(null);
  const [counter, setCounter] = useState(0);
  const [fingerprint, setFingerprint] = useState("");
  const [user, setUser] = useState(null);
  const [driveToken, setDriveToken] = useState(null);
  const [displaySearch, setSearchDisp] = useState(false);


  useEffect(() => {
    async function getFingerprint() {
      const f = await new Promise((resolve, reject) => {
        Fingerprint2.get(components => {
          const values = components.map(component => component.value);
          const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
          resolve(fingerprint);
        });
      });
      setFingerprint(f);
    }
    getFingerprint("");
  }, []);


  useEffect(() => {
    if(!fingerprint || !driveToken) return;

    async function checkVectorizedFiles() {
      try {

        const res = await axios.post(URL + '/api/crud', {
        action: "checkCollectionExists",
          user_id: fingerprint, 
          collection: driveToken.user_email,
        });
        setSearchDisp(res.data);

      } catch (err) {
        console.log(err);
      }
    }

    checkVectorizedFiles();
  }, [driveToken, fingerprint]);

  useEffect(() => {
    if(!fingerprint) return;

    async function fetchRecentAuthToken() {
      try {

        const res = await axios.post(URL + '/api/crud', {
        action: "findMostRecent",
          user_id: fingerprint, 
          collection: "drive_tokens"
        });

        const unixTimeDate =  Date.now();

        if( res.data.expiry_date < unixTimeDate || !res.data){
          console.log("The token is expired");
          setDriveToken("")
        }else{
          setDriveToken(res.data);

        }
      } catch (err) {
        console.log(err);
      }
    }

    fetchRecentAuthToken();
  }, [fingerprint]);


  useEffect(() => {
    if(!fingerprint) return;
    async function fetchSignupToken() {
      try {

        const res = await axios.post(URL + '/api/crud', {
          action: "findMostRecent",
          collection: "vectorize/signup",
          user_id: fingerprint
        });
        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchSignupToken();

  }, [fingerprint]);


  async function request_openai( query) {
    const request_url = "https://openai-juan.herokuapp.com/api/prompt_me";
    const openai = await axios.post(request_url, { data: query  })
    return openai
  }
  async function onSearch(e) {
    setLoading(true);
    try{
    const openai_res = await request_openai( searchText)
    const w = { prompt: searchText, res: openai_res.data.text};
    const i = [...backlog, w].reverse()
    setCounter( counter +1 );
    setBacklog(i)
    
  }catch(e){
    const w = { prompt: searchText, res: "There was an error. Try again later!"};
    const i = [...backlog, w].reverse()
    console.log(e)
    setBacklog(i)
  }
  setLoading(false);
  };

  useEffect(() => {
    geoLocation().then(loc =>{
      const w = {
        timestamp: newMHDMY(),
        path: "vect-cong/" + loc.country_name,
        loc
      }
      create_db_entry(w);
      setGeo(w);
    });
  }, []);

  

  console.log(searchText);
  return (
    <div className="App">
      <header className="App-header">
      <Routes>
          <Route path="/" element={<LandingPage fingerprint={fingerprint} 
          user={user} driveToken={driveToken} setDriveToken={setDriveToken}
          displaySearch={displaySearch}/>} 
          />
          <Route path="/congress" element={<GovtSearch />} />
          <Route path="/leaphack" element={<LeapH />} />
      </Routes>
      
      </header>
    </div>
  );
}

export default App;
