
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Space, Card, Tag, Button, Spin, Input, Divider, Layout, Dropdown } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import Lense from "../ss.png";
import { create_db_entry, newMHDMY } from "../utils/index";
import { URL } from '../config/index';
import SearchPC from './Cards/SearchPC';

const { Search } = Input;


export default function SearchBar({ geo, setResults, driveToken }) {
    const [file, setFile] = useState(null);
    const [id, setId] = useState("");
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    // const [backlog, setBacklog] = useState([]);
    const [pcVectors,setPcVectors] = useState([])
    const [counter, setCounter] = useState(0);
    const [loadE, setLoadE] = useState(false);

    async function queryNamespaceVectors(){
        try {
          const res = await axios.post(URL + '/api/drive_questions', {
            google_auth: driveToken,
            query_sentence : searchText,
            action: 'similar'
          });
          console.log("Most similar vectors ", res.data, typeof(res.data));
          setPcVectors(res.data.result);
        } catch (err) {
          console.log("Folders error", err);
        }
      };


    async function onSearch(e) {

        setLoading(true);
        
        try {
            await queryNamespaceVectors();

        } catch (e) {
            

            setResults([])
        }
        setLoading(false);
    };

    function onChange(e) {
        setSearchText(e.target.value)
    }

  


    return (
        <div style={{ width:'100%',  margin:'0 auto', justifyContent:'center', alignContent:'center' }}>
            <Card style={{ border:'transparent', margin:'0 auto',  }}>
            <h3>Search Guidelines</h3>
            <p>Results are displayed by relevance based on your question.</p>

                        <Divider style={{ borderColor: "transparent" }}></Divider>
                        <Search
                            placeholder="input search text"
                            allowClear
                            enterButton={<SearchOutlined />}
                            size="medium"
                            onSearch={onSearch}
                            onChange={onChange}
                            value={searchText}
                        />
                        {loading && <Divider><p>Searching... <LoadingOutlined /></p></Divider>}
                        <br></br>
                        <br></br>
                        {!!pcVectors && <div style={{maxHeight: '400px', overflow: 'auto'}}>

                   {  pcVectors.map( v=><SearchPC vectorObject={v} />) }
                   </div>}
            </Card>
        </div>
    );
}