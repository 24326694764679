
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Space, Card, Tag, Button, Spin, Input, Divider, Layout, Switch } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import Lense from "../ss.png";
import { create_db_entry, newMHDMY } from "../utils/index";
import { URL } from '../config/index';
import SearchPC from './Cards/SearchPC';
import Cong from '../MockupData/congr.png';
import Openai from '../MockupData/openai.png';
import Google from '../MockupData/google.png';

const { Search } = Input;


export default function SearchBar({ geo, setResults, driveToken }) {

    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [actionB, setActionB] = useState(false);
    const [qna, setQnA] = useState("");
    // const [backlog, setBacklog] = useState([]);
    const [pcVectors, setPcVectors] = useState([]);
    const onToggleChange = (checked) => {
        setActionB(!actionB);
    };

    async function queryDriveQuestions() {
        try {
            const res = await axios.post(URL + '/api/drive_questions', {
                google_auth: {
                    user_email: 'congress',
                },
                query_sentence: searchText,
                action: actionB ? 'questions' : 'semantic'
            });
            // console.log("Most similar vectors ", res.data, typeof (res.data));
            console.log("Most similar vectors ", res.data.result, "YEAH");
            if (!actionB) {
                setQnA("");
                setPcVectors(res.data.result);

            } else {
                setQnA(res.data.result);
                setPcVectors([]);
            }
        } catch (err) {
            console.log("Questions error", err);
        }
    };

    console.log(actionB);
    async function onSearch(e) {

        setLoading(true);

        try {
            const r = await queryDriveQuestions();

        } catch (e) {


            setResults([])
        }
        setLoading(false);
    };

    function onChange(e) {
        setSearchText(e.target.value)
    }




    return (
        <div style={{ width: '97%', maxWidth: '600px', margin: '0 auto', justifyContent: 'center', alignContent: 'center', marginTop: '20px', marginBottom: '50px' }}>
            <Card style={{ border: 'transparent', margin: '0 auto', }}>
                <div>
                    <img
                        style={{ width: "30%", margin: "0 auto", marginTop: "40px", marginRight: "10px" }}
                        className="spin"
                        alt="example"
                        src={Openai}
                    />
                    <img className=""
                        style={{ width: "30%", maxWidth: '300px', margin: "0 auto", marginTop: "40px" }}
                        alt="example"
                        src={Cong}
                    />
                </div><br></br>


                <p style={{fontSize:"10px", color:'lightgray', maxWidth:"400px", margin:'0 auto', textAlign:'left', alignContent:'left'}}>
                <b>H.R.5376 - </b>Inflation Reduction Act of 2022, 
                <b>H.R.1808 -</b> Assault Weapons Ban of 2022, 
                <b>H.Res.57 -</b> Impeaching Joseph R. Biden,
                <b>H.R.25 -</b> FairTax Act of 2023
                <b>H.R.25 -</b> FairTax Act of 2021,
                <b>H.R.21 -</b> Strategic Production Response Act, 
                <b>S.596 -</b>Treat and Reduce Obesity Act of 2021
                </p>
                <br></br>
                <p style={{ textAlign: 'left', marginTop: '50px', width: '100%', maxWidth: '400px', margin: '0 auto' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ maxWidth: '20px', marginRight: '10px' }} src={Google} />
                        <a href="https://drive.google.com/drive/folders/1sLDhobqWwVqEGxN0NC7DGUgXaAuygaCe?usp=sharing">Google Drive Folder</a>
                    </div>
                    You can search for extracts or ask questions directly.
                    When searching for extracts, results are displayed by relevance based on your question. Questions will be answered in our best capacity.
                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
                        <p style={{ marginRight: 10 }}>{actionB ? "Question answering" : "Semantic search"}</p>
                        <Switch onChange={onToggleChange} />
                    </div>
                </p>


                <Divider style={{ borderColor: "transparent" }}></Divider>
                <Search
                    placeholder="input search text"
                    allowClear
                    enterButton={<SearchOutlined />}
                    size="medium"
                    onSearch={onSearch}
                    onChange={onChange}
                    value={searchText}
                />
                {loading && <Divider><p>Searching... <LoadingOutlined /></p></Divider>}
                <br></br>
                <br></br>
                {!!pcVectors && pcVectors.length > 0 && <div style={{ maxHeight: '400px', overflow: 'auto' }}>

                    {pcVectors.map(v => <SearchPC vectorObject={v} />)}
                </div>}
                {qna && qna.length > 0 && <Card style={{ width: "97%", maxWidth: "600px", alignContent: "left", textAlign: "left", marginTop: "4px", marginBottom: "15px" }}>

                    <p style={{ color: "#282c34" }}> <Tag style={{ width: "80px" }} color="orange">Query:</Tag>{searchText}</p>
                    <Tag style={{ width: "80px" }} color="green">Response:</Tag>
                    <p style={{ color: "#282c34" }}>{qna}</p>
                </Card>}
            </Card>
        </div>
    );
}